import thumbnail from "@/assets/thumbnail.webp";

export default function HowItWorks() {
  return (
    <section id="how-it-works" className="mb-20  px-5 md:px-0">
      <div className="flex flex-col justify-between md:gap-36 md:flex-row">
        {/* left */}
        <div className="flex flex-col gap-6 justify-center max-w-2xl">
          <h4 className="mb-6 text-3xl font-bold uppercase md:text-5xl">
            SEAMLESS INTEGRATION
          </h4>
          <p className="mb-8">
            ELOOP revolutionizes the process of converting physical assets into
            digital tokens, granting holders the right to a share of the
            generated revenues, proportionate to the RWAs performance.
            Blockchain technology ensures transparency in all aspects. ELOOP
            simplifies the integration into Web3 technology, eliminating the
            need for previous blockchain expertise.
          </p>
        </div>
        {/* right */}
        <div className="max-w-2xl">
          <div className="flex flex-col gap-8 mb-11">
            <div className="flex flex-col">
              <div className="flex gap-5 -translate-x-12">
                <div className="relative flex items-center justify-center w-7 h-7 mb-4 rounded-full bg-primary">
                  <div className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-white">
                    <strong className="text-md font-semibold text-primary">
                      1
                    </strong>
                  </div>
                </div>
                <strong className="mb-4 text-2xl font-semibold text-primary">
                  All-in-one solution
                </strong>
              </div>

              <p>
                ELOOP encompasses the entire journey from project management,
                regulatory compliance and guidance, white-label platform
                customization and asset token generation.
              </p>
            </div>
            <div className="flex flex-col">
              <div className="flex gap-5 -translate-x-12">
                <div className="relative flex items-center justify-center w-7 h-7 mb-4 rounded-full bg-primary">
                  <div className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-white">
                    <strong className="text-md font-semibold text-primary">
                      2
                    </strong>
                  </div>
                </div>
                <strong className="mb-4 text-2xl font-semibold text-primary">
                  Effortless Web2-to-Web3 integration
                </strong>
              </div>
              <p>
                The ELOOP Network ensures a seamless integration for businesses
                and individuals into the world of Web3. Guaranteeing a smooth
                onboarding and registration process.
              </p>
            </div>
            <div className="flex flex-col">
              <div className="flex gap-5 -translate-x-12">
                <div className="relative flex items-center justify-center w-7 h-7 mb-4 rounded-full bg-primary">
                  <div className="absolute flex items-center justify-center w-6 h-6 rounded-full bg-white">
                    <strong className="text-md font-semibold text-primary">
                      3
                    </strong>
                  </div>
                </div>
                <strong className="mb-4 text-2xl font-semibold text-primary">
                  Supporting Cash and Crypto
                </strong>
              </div>
              <p>
                Integrated gateways within the network enable every participant
                to utilize familiar payment methods such as credit cards and
                PayPal, as well as cryptocurrencies and the ELOOP Coin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
