export default function EloopCoin() {
  return (
    <section id="eloop-coin" className=" px-5 md:px-0 mb-20">
      {/* data */}
      <div className="flex flex-col justify-between md:gap-8 md:flex-row">
        <h4 className="mb-6 text-3xl font-bold uppercase md:text-5xl">
          One coin <br />
          MANY USE CASES
        </h4>
        <div className="max-w-2xl">
          <p className="mb-20">
            The ELOOP Coin is the fuel of the network. Unlike numerous other
            coins and tokens, the ELOOP Coin is deeply embedded in real-world
            use cases. It serves as the connective tissue between all ecosystem
            stakeholders and is utilized, among other things, as the main method
            for rewards, to generate fees with DeFi functions, and to access
            exclusive partner offers within the ELOOP Network. As an ERC20
            token, it is designed to maximize compatibility and facilitate
            adoption by third parties.
          </p>
        </div>
      </div>
      {/* image */}
      <div className="object-cover w-full h-[342px] bg-gray-700 mb-20  bg-[url('../assets/eloop-coin-asset.webp')] bg-cover bg-no-repeat bg-center rounded-3xl" />
      <div className="flex mt-40 flex-col justify-between md:gap-8 md:flex-row">
        <h4 className="mb-6 text-3xl font-bold uppercase md:text-5xl">
          OUR VISION
        </h4>
        <div className="max-w-2xl">
          <p className="mb-20">
            ELOOP revolutionizes the implementation of sustainable projects and
            reshapes the way people engage with them, spanning from shared
            mobility to renewable energy. We envision a future where funding and
            financial integration are democratized, allowing individuals to
            invest in fractional ownership of physical assets like solar power
            plants or car-sharing fleets (real-world assets). This approach not
            only empowers everyone to be part of the sustainable movement but
            also supports the growth of green businesses.
            <br />
            <br /> This is the ELOOP Network, an ecosystem for machine RWAs.
          </p>
        </div>
      </div>
      {/* image */}
      <div className="object-cover w-full h-[342px] bg-gray-700 mb-20  bg-[url('../assets/turbines.webp')] bg-cover bg-no-repeat bg-center rounded-3xl" />
    </section>
  );
}
