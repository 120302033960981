import turbines from "@/assets/turbines.webp";
import {
  About,
  FAQ,
  Hero,
  HowItWorks,
  Tech,
  Vision,
} from "@/components/sections";
import EloopCoin from "@/components/sections/eloop-coin";
import { Post, postBaseSchema } from "@/renderer/schema";
import { useData } from "@/renderer/useData";
import { Contact } from "lucide-react";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import PopularBlogs from "@/components/sections/popular-blogs";

export { Page };

function Page() {
  const { popularPosts } = useData<{
    popularPosts: z.infer<typeof postBaseSchema>[];
  }>();
  return (
    <main className="flex flex-col overflow-x-hidden   min-h-screen md:px-[90px] py-8 mx-auto max-w-[1560px] relative">
      <Hero />
      <Tech />
      <Vision />
      <HowItWorks />
      <EloopCoin />

      <About />
      <section
        id="popular-blogs"
        className="mb-20 px-5 md:px-0 md:mb-40 flex flex-col"
      >
        <PopularBlogs posts={popularPosts} />
        <a href="/blog/" className="self-center z-10">
          <Button className="px-20 bg-[#5263ff] mt-8">See more</Button>
        </a>
      </section>
      <Contact />
      <FAQ />
    </main>
  );
}
