import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "../ui/button";
import halfTesla from "@/assets/half-tesla.webp";
import charger from "@/assets/charger.webp";
import windTurbines from "@/assets/wind-turbines.webp";

const items = [
  {
    number: "01",
    title: "Mobility",
    description: `Regardless of whether it’s car, scooter, bicycle sharing or ride hailing, by tokenizing these vehicles, shared mobility providers unlock new standards for customer loyalty, user experience, and sustainable growth.`,
  },
  {
    number: "02",
    title: "Energy & Charging",
    description: `Financing high-value assets such as wind turbines,solar power plants or charging stations often face limited options. With the tokenization of RWAs, providers gain access to revolutionary financing avenues.`,
  },
  {
    number: "03",
    title: "And more",
    description: `The ELOOP Network empowers both Web2 and Web3 companies to fractionalize their machine assets using tokenization technology. This approach not only creates new opportunities for funding but also offers substantial marketing advantages.`,
  },
];

export default function Tech() {
  return (
    <section id="tech" className="mb-20 px-5 md:px-0  md:mb-40">
      {/* data */}
      <h4 className="mb-6 text-3xl font-bold uppercase md:text-5xl">
        Connecting Web2 & Web3 technologies
      </h4>

      {/* desktop */}
      <div className="hidden md:block">
        <BokkylSwiper />
      </div>

      {/* mobile */}
      <Accordion type="single" collapsible className="w-full md:hidden">
        {items.map((item, index) => {
          return (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger>
                <strong className="text-4xl font-bold text-primary">
                  {item.number}
                </strong>
                <div className="uppercase">{item.title}</div>
              </AccordionTrigger>
              <AccordionContent>
                <img
                  src={
                    item.number === "02"
                      ? windTurbines
                      : item.number === "03"
                      ? charger
                      : halfTesla
                  }
                  alt=""
                  className="mx-auto"
                />
                <p className="mb-10">{item.description}</p>
                <a href="#contact">
                  <Button className="w-full ">Get in touch</Button>
                </a>
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </section>
  );
}

const BokkylSwiper = () => {
  type ActiveTab = 0 | 1 | 2;
  const [activeTab, setActiveTab] = useState<ActiveTab>(0);

  return (
    <div className="flex justify-around w-full transition-all py-4 divide-x-2 divide-primary/50">
      {items.map((item, index) => {
        const isActive = activeTab === index;
        return (
          <div
            key={index}
            onClick={() => setActiveTab(index as ActiveTab)}
            className={`w-full`}
          >
            <div
              className={`flex  flex-col py-4  h-full ${
                !isActive
                  ? "items-center justify-between cursor-pointer"
                  : index === 0
                  ? "pl-0"
                  : "pl-4"
              }`}
            >
              <strong className="text-2xl 2xl:text-4xl font-bold text-primary">
                {item.number}
              </strong>
              <div
                className={`uppercase font-bold  text-center text-2xl 2xl:text-4xl  ${
                  isActive ? "hidden" : ""
                }`}
              >
                {item.title}
              </div>
              {isActive && (
                <div className="flex items-center w-[750px] gap-12">
                  <div className="flex flex-col justify-around h-full mt-6">
                    <div className="uppercase my-5 font-bold text-2xl 2xl:text-4xl ">
                      {item.title}
                    </div>
                    <div>{item.description}</div>
                    <a href="#contact">
                      <Button className="w-fit px-8 mt-5">Get in touch</Button>
                    </a>
                  </div>
                  {/* image */}
                  {item.number === "02" ? (
                    <div className="2xl:h-52 h-32 w-auto  aspect-square bg-[url('../assets/wind-turbines.webp')] bg-cover bg-no-repeat bg-center" />
                  ) : item.number === "03" ? (
                    <div className="2xl:h-52 h-32 w-auto  aspect-square bg-[url('../assets/charger.webp')] bg-cover bg-no-repeat bg-center" />
                  ) : (
                    <div className="2xl:h-52 h-32 w-auto  aspect-square bg-[url('../assets/half-tesla.webp')] bg-cover bg-no-repeat bg-center" />
                  )}
                  {/* <div className="2xl:h-52 h-32 w-auto  aspect-square bg-[url('../assets/half-tesla.webp')] bg-cover bg-no-repeat bg-center" /> */}
                </div>
              )}
              {!isActive && <div className="mb-8 text-gray-400">More</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};
