import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordionv2";

const items = [
  {
    title: "What is ELOOP Network and how does it work?",
    content:
      "ELOOP Network allows everyone to directly interact with industries, companies or service providers and to transparently invest and benefit from the tokenization of Real World Assets. Blockchain technology ensures transparency in all aspects of the ELOOP Network and provides safety for investment funds and revenue streams. Through ELOOP Network, machine Real World Assets such as electric cars in a car sharing fleet or solar and wind energy infrastructure are being tokenized on the blockchain, allowing users to partake in the value creation. Without any middle-men and 100% transparency.",
  },
  {
    title: "What are the advantages of RWA tokenization for businesses?",
    content:
      "Tokenization of Real World Assets (RWAs) enables a whole new approach for financing assets and creating communities. Direct participation from consumers cuts out any middle men and any additional fees or brokerage costs. Gone are the days of institutional fundraising rounds and the dependency on the good will of banks or institutions and their ever-increasing interest rates. Furthermore, as private investors can simultaneously be customers in many of the supported business sectors, an unprecedented level of loyalty and care for the assets emerges.",
  },
  {
    title: "Is the ELOOP Network already accessible?",
    content:
      "The ELOOP Network platform will be expanded in the first half of 2024 to onboard new businesses across the globe, for which acquisition has been happening throughout 2023 already.  Until then the primary use case is the ELOOP car sharing fleet, depicted by the ELOOP ONE Token, to further establish the initial use case.",
  },
  {
    title: "What is the ELOOP Coin?",
    content:
      "The ELOOP Coin is an EVM-compatible utility token. All rewards and revenue sharings are processed in ELOOP Coin. Pay-Outs can be processed with FIAT currencies or stable coins as well though.",
  },
  {
    title:
      "What are the differences between the ELOOP Coin and other cryptocurrencies?",
    content:
      "Contrary to most crypto currencies, the ELOOP Coin is deeply rooted in a real world use case. The ongoing FIAT (regular nation-issued currencies) influx sets it apart from all strictly trade-based crypto currencies. However, the ELOOP Coin is featuring all the superior characteristics of crypto currencies, such as self-custody, limitless transfer and safety. The ELOOP Network runs on a EVM-compatible chain, so all relevant aspects of the biggest smart-contract allowing blockchain Ethereum are incorporated.",
  },
];

export default function FAQ() {
  return (
    <section
      id="faq"
      className="flex flex-col px-5 md:px-0 items-center justify-center mb-20 md:mb-40 md:gap-8"
    >
      {/* title */}
      <h4 className="mb-6 text-3xl font-bold uppercase md:text-5xl">FAQ</h4>

      {/* tabs */}
      <Accordion type="single" collapsible className="w-full">
        {items.map((item, index) => {
          return (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger>{item.title}</AccordionTrigger>
              <AccordionContent>{item.content}</AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </section>
  );
}
