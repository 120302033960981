export default function About() {
  return (
    <section
      id="about-us"
      className="mb-20 px-5 items-center justify-center flex flex-col w-full md:px-0 md:mb-40 "
    >
      {/* data */}
      <div className="flex w-full flex-col mb-16 justify-between md:gap-8 md:flex-row">
        <div className="flex flex-col gap-6 justify-center max-w-2xl">
          <h4 className="mb-6 whitespace-pre-line text-3xl font-bold uppercase md:text-5xl">
            {`SHOWCASE ELOOP\nE-Carsharing`}
          </h4>
          <div className="max-w-2xl">
            <p className="mb-8">
              Since 2019, ELOOP has been operating its own car sharing service
              with a fleet of several hundred Tesla vehicles in Vienna, Austria.
              A year after its launch, the startup has taken a ground-changing
              step by tokenizing a portion of their fleet, yielding impressive
              and unforeseen outcomes:
            </p>
          </div>
        </div>
        {/* right */}
        <div className="max-w-2xl mr-16">
          {/* stats */}
          <div className="flex flex-col gap-6 mb-11">
            <div>
              <strong className="text-4xl font-semibold text-primary">
                $1.6 million
              </strong>
              <p>worth of tokens were sold without any marketing expenses</p>
            </div>
            <div>
              <strong className="text-4xl font-semibold text-primary">
                100,000+
              </strong>
              <p>customers creating an extensive and loyal user base</p>
            </div>
            <div>
              <strong className="text-4xl font-semibold text-primary">
                +200 % revenue
              </strong>
              <p>generated by token holders compared to regular customers</p>
            </div>
          </div>
        </div>
      </div>
      {/* image */}
      <div className="object-cover w-full md:h-[450px] h-[300px] shadow-lg bg-gray-700   bg-[url('../assets/thumbnail.webp')] bg-cover bg-no-repeat bg-center rounded-3xl" />
    </section>
  );
}
