import vision1 from "@/assets/vision1.svg";
import vision2 from "@/assets/vision2.svg";
import vision3 from "@/assets/vision3.svg";

export default function Vision() {
  return (
    <section id="vision" className=" px-5 mb-20 md:px-0 ">
      <h4 className="mb-16 text-3xl font-bold uppercase md:mb-24 md:text-5xl">
        ELEVATING GROWTH FOR ASSET-HEAVY COMPANIES
      </h4>
      <div className="flex mb-20 items-center flex-col gap-8 md:flex-row md:gap-4">
        <div className="md:w-1/3 flex flex-col items-center">
          <img src={vision1} className="w-32" alt="vision1" />
          <h6 className="mb-2 text-xl ">Increase your company's liquidity</h6>
        </div>
        <div className="md:w-1/3 flex flex-col items-center">
          <img src={vision2} className="w-32" alt="vision2" />
          <h6 className="mb-2 text-xl">Reduce capital expenses</h6>
        </div>
        <div className="md:w-1/3 flex flex-col items-center">
          <img src={vision3} className="w-32" alt="vision3" />
          <h6 className="mb-2 text-xl ">
            Unlock unique marketing opportunities
          </h6>
        </div>
      </div>
      {/* data */}

      {/* more */}
    </section>
  );
}
